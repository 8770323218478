import React, { Component } from 'react'
import { Link } from 'gatsby'
import '../components/bootstrap-marketing/startup';
import { graphql } from 'gatsby'
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import Helmet from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import Layout from '../components/BSLayout';
import MobileNavbar from '../components/MobileNavbar';
import DesktopNavbar from '../components/DesktopNavbar';
import FooterSection from '../components/Footer';
import Stage from '../components/stage';


//import phoneImage from '../images/app-iphone.png';
import rentalsImage from '../images/rentals_sample.png';
import presenceImage from '../images/presence_sample.png';
import enquiryImage from '../images/enquiry_sample.png';
import galleryImage from '../images/gallery_sample.png';
//import floorplanImage from '../images/floor_plan_sample.png';
import siteSvg from '../images/presence-site-tick.svg';



const cssExampleButon = css`
margin-right: 1rem;
`;

const PrimaryCTA = () => (
  <div className="block-xs-middle pb-5">
    <div className="container">
      <div className="row">
        <div className="col-sm-10 col-lg-8">
          <h1 className="block-titleData frequency">Stand out online</h1>
          <p className="lead mb-4">A fully hosted and simply beautiful online presence for your community.</p>
          <Link css={cssExampleButon} to="/contact" className="btn btn-primary btn-lg">Speak with us</Link>
          <a  href="http://monolith.fief.sh/" rel="noreferrer" target="_blank" className="btn btn-secondary btn-lg">Example site <FontAwesomeIcon icon={faExternalLinkAlt} /></a>
        </div>
      </div>
    </div>
  </div>
)


const Feature = (props) => (
      <div className="col-sm-6 mb-3">
        <h5>{props.header}</h5>
        <p>{props.desc}</p>
      </div>
);

const bookingFeatures = [
    {
        header: "Mobile optimised",
        desc: "Residents have gone mobile, that's why our sites are built with mobile in mind."
    },
    {
        header: "Fast",
        desc: "We use the latest techniques to ensure your site loads fast."
    },
    {
        header: "Floor plan galleries",
        desc: "We can automatically split floorplan documents to make viewing easier."
    },
    {
        header: "Always modern",
        desc: "We keep our templates up to date so your site keeps looking good."
    }
];
const SectionDesign = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" alt="" src={presenceImage} style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Design</h6>
        <h2>Simply beautiful</h2>
        <p className="lead mb-4">Hastle free modern design keeps your online presence looking great.</p>
        <div className="row d-none d-md-flex">
            { bookingFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
    </div>

  </div>
</div>
)

const paymentFeatures = [
    {
        header: "Full details pop out",
        desc: "Each listing pops out into a full display to allow your apartments room to shine."
    },
    {
        header: "Galleries",
        desc: "Show off your great apartments, our listings include full gallery and floorplan support."
    },
];

const SectionPayments = () => (
<div className="block block-inverse block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Listings</h6>
        <h2>Keep people up to date on what's available</h2>
        <p className="lead mb-4">Our rental listings let you show off what's available in your building.</p>
        <div className="row d-none d-md-flex">
            { paymentFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={rentalsImage} alt="Example listings" style={{"width": "100%"}} />
      </div>
    </div>

  </div>
</div>
)

const galleryFeatures = [
    {
        header: "Optimised",
        desc: "We optimise each image to ensure the best quality for each device."
    },
    {
        header: "Floorplans",
        desc: "We have special handling for floorplans to allow vistors to easily browse."
    },
];

const SectionGalleries = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">
      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={galleryImage}  alt="example gallery" style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Galleries</h6>
        <h2>Allow your Images to shine with our galleries</h2>
        <p className="lead mb-4">We optimise each Image to ensure the best experience for any device, including explicit support for handling floorplan pdfs.</p>
        <div className="row d-none d-md-flex">
            { galleryFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
    </div>

  </div>
</div>
)


const floorplanFeatures = [
    {
        header: "Global CDN",
        desc: "Helps ensure your site loads quickly no matter where your visitor is."
    },
    {
        header: "Managed Security",
        desc: "We manage all security aspects of the site so your can focus on what it says."
    },
    {
        header: "Secure Connection",
        desc: "All sites include https support to ensure content is delivered without modification."
    },
];

const SectionFloorplans = () => (
<div className="block block-inverse block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Hosting</h6>
        <h2>Relax and let us worry about the technology details.</h2>
        <p className="lead mb-4">Our system is fully hosted, backed by a global server network and managed by us for your peace of mind.</p>
        <div className="row d-none d-md-flex">
            { floorplanFeatures.map( f => <Feature key={f.header} {...f} />) }
        </div>
      </div>
      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={siteSvg} alt="" style={{"width": "100%"}} />
      </div>
    </div>

  </div>
</div>
)

const managementFeatures = [
    {
        header: "Fight spam",
        desc: "Our enquiry forms are design to help minimise spam."
    },
    {
        header: "Flexible",
        desc: "Customise the enquiry forms to get the info you need."
    },
];

const SectionManagement = (props) => (
<div className="block block-secondary app-iphone-block">
  <div className="container">
    <div className="row app-align-center">

      <div className="col-sm-5 d-none d-md-block">
        <img className="app-iphone" src={enquiryImage} alt="Example enquiry form" style={{"width": "100%"}} />
      </div>
      <div className="col-md-6 col-sm-12 ml-auto">
        <h6 className="text-muted text-uppercase">Enquiries</h6>
        <h2>Easily accept new enquiries</h2>
        <p className="lead mb-4">Our flexible enquiry forms let you get the details you need from potential new residents.</p>
        <div className="row d-none d-md-flex">
            { managementFeatures.map( f => <Feature key={f.header}{...f} />) }
        </div>
      </div>
    </div>

  </div>
</div>
)



const cssCTAButton = css`
    margin-top: 3rem;
`

const CTASection = () => (
    <div className="block py-5">
      <div className="container text-center">
        <div className="row mb-5 justify-content-center">
          <div className="col-10 col-md-8 col-lg-10">
            <h6 className="text-muted text-uppercase mb-2">Get in touch</h6>
            <h2>Get in touch to see how we can help you.</h2>
            <Link css={cssCTAButton} className="btn btn-primary btn-lg" to="/contact">Speak with us</Link>
          </div>
        </div>
      </div>
    </div>
);


const HeadData = () => (
  <Helmet>
    <title>Beautiful fully hosted apartment websites - Castellan</title>
    <meta name="description" content="A simple but beautiful web presence for your condominium building. Our website system is fully hosted with no setup fees." />
    <meta name="keywords" content="multi-family residential, apartment, condominium, website" />
  </Helmet>
)

const cssNavbar = css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    font-weight: 500;
    letter-spacing: .05em;
`
const cssCTABackground = css`
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background: #000;
`
class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }
  navToggle = () => {
    const { menuOpen } = this.state;
    this.setState({ menuOpen: !menuOpen});
  }

  render() {
    const { data} = this.props;
    const { menuOpen } = this.state;
    return (
        <Layout>
          <HeadData />
          <div>
            <MobileNavbar />
            <Stage isOpen={menuOpen}>
              <div className="block block-inverse block-fill-height app-header" >
                <div css={cssNavbar} >
                <DesktopNavbar onNavToggle={this.navToggle} transparent={true} />
                </div>
                <Img 
                fluid={data.primaryBackground.childImageSharp.fluid} 
        css={cssCTABackground}
                />
                <PrimaryCTA />
              </div>
                <SectionDesign />
                <SectionPayments />
                <SectionGalleries />
                <SectionFloorplans />
                <SectionManagement />
              <CTASection />
              <FooterSection />
            </Stage>
          </div>
        </Layout>
    );
};
}

export const query = graphql`
    query {
      primaryBackground: file(relativePath: { eq: "presence_building.jpg" }) {
        childImageSharp {
          fluid(maxWidth:3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
`

export default IndexPage